.toolbar {
    --background: #003644;
    --min-height: 60px;
    --color: #ffffff;

    .toolbar-title {
        font-family: 'D-DINCondensed';
        font-weight: bold;
        font-size: 24px;
    }
}

.text-color-primary {
    color: var(--ion-color-primary);
}

input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

h1 {
    font-family: 'D-DINCondensed';
    font-weight: bold;
    color: var(--ion-color-primary);
    font-size: 24px;
}

h2 {
    font-family: 'PFDinTextCompPro-Light';
    color: var(--ion-color-primary);
    font-size: 24px;
}

.label {
    font-family: 'PFDinTextCompPro-Light';
    font-size: 18px;
    color: var(--ion-color-primary);

    &.label-white {
        color: #ffffff;
    }

    &.label-big {
        font-size: 24px;
    }
}

.input-text {
    font-family: 'PFDinTextCompPro-Light';
    height: 40px;
    border: 1px solid #e0e0e0;
    color: #717171;
    padding-left: 0;
    text-indent: 10px;
    outline: 0;
    border-radius: 0;
    background-color: var(--ion-background-color);

    &::placeholder {
        color: #cecece;
    }

    &:focus {
        &:not(.autocomplete) {
            border: 1px solid var(--ion-color-primary);
            outline: 0;
        }
    }

    &.ng-invalid:not(.ng-untouched) {
        border-color: #ff0000;
    }

    // Focus event does not work with an ion-input, so use this.
    &.has-focus {
        border: 1px solid var(--ion-color-primary);
    }

    &.input-error {
        border-color: #ff0000;
    }

    &.readonly {
        border: 1px solid #e0e0e0;
        background-color: #ececec;

        &:focus {
            border: 1px solid #e0e0e0;
            outline: 0;
        }
    }
}

.autocomplete-wrapper {
    position: relative;
    color: #cecece;

    ion-icon {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
    }
}


.date-picker {
    font-family: 'PFDinTextCompPro-Light';
    height: 40px;
    border: 1px solid #e0e0e0;
    --ion-text-color: #717171;
    --placeholder-color: #cecece;
    --padding-start: 10px;

    &.input-error {
        border-color: #ff0000;
    }
}

.input-select {
    font-family: 'PFDinTextCompPro-Light';
    height: 40px;
    border: 1px solid #e0e0e0;
    background-color: #ffffff;
    color: #717171;
    padding-left: 0;
    text-indent: 10px;
    outline: 0;
    border-radius: 0;

    option {
        color: #717171;
    }

    &.invalid {
        color: #cecece;

        option {
            color: #717171;
        }
    }
}

.btn-login {
    font-family: 'PFDinTextCompPro-Light';
    background-color: var(--ion-color-secondary);
    color: var(--ion-color-primary);
    font-size: 18px;
    height: 40px;
    text-align: center;
    width: 100%;
    text-transform: uppercase;

    &:disabled {
        background-color: #ffe098;
    }
}

.btn-delete {
    font-family: 'PFDinTextCompPro-Light';
    background-color: #bd2049;
    color: #ffffff;
    font-size: 18px;
    height: 40px;
    text-align: center;
    width: 100%;
    text-transform: uppercase;
}


.m-0-auto {
    margin: 0 auto;
}

.icon-calendar {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: #7D7D7D;
}

.table-fc {
    font-family: 'PFDinTextCompPro-Light';
    font-size: 18px;
    color: #717171;

    thead {
        th {
            border-top: 0;
            border-bottom: 0;
            border-color: #002731;
        }

        tr {
            background-color: var(--ion-color-primary);
            color: #ffffff;
            text-transform: uppercase;
        }
    }

    tbody {
        tr {
            td {
                border: 1px solid #e0e0e0;

                &.status-open {
                    color: #6f946a;
                }

                &.status-denied {
                    color: #d12c2c;
                }

                &.status-inprogress {
                    color: #fdc645;
                }

            }

            &:nth-child(odd) {
                background-color: #ffffff;
            }

            &:nth-child(even) {
                background-color: #f4f4f4;
            }
        }
    }
}

.font-primary {
    font-family: 'PFDinTextCompPro-Light';
    color:  var(--ion-color-primary);
}

.color-primary {
    color:  var(--ion-color-primary);
}

.h-100 {
    height: 100%;
}