@font-face {
    font-family: 'D-DINCondensed';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/D-DINCondensed.woff2') format('woff2'),
         url('/assets/fonts/D-DINCondensed.ttf') format('truetype'),
}

@font-face {
    font-family: 'PFDinTextCompPro-Light';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/PFDinTextCompPro-Light.ttf') format('truetype'),
}

@font-face {
    font-family: 'Font-Awesome-5-Free-Regular';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/Font-Awesome-5-Free-Regular-400.otf') format('opentype'),
}
